.App {
  font-family: sans-serif;
  position: relative;
  width: 80vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  color: #14877c;
}
.heading {
  color: #14877c;
  border-bottom: 1px solid #14877c;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
input {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #14877c;
  padding: 10px;
  font-size: 14px;
}

.paginationCategory {
  /* height: 38px; */
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  -webkit-user-select: none;
  /* user-select: none; */
  /* font-weight: 600; */
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid grey;
  background-color: grey;
  cursor: pointer;
  margin-left: 0px 15px;
  min-width: 29px;
  margin-left: 8px !important;
  border-radius: 3px !important;
}


table {
  width: 100%;
  padding: 1em;
  margin: 20px auto;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

.table-responsive1 {
  display: block;
  width: 100%;
  
  -webkit-overflow-scrolling: touch;
}

thead {
  background-color: #FF8306;
  font-size: 16px;
}

th {
  text-align: left;
  padding: 8px;
  /* background-color: 1px solid #ffffff;
  color: lightgrey; */
}
td {
  text-align: left;
  padding: 8px;
}

tr {
  height: 50px;
}

tr:nth-child(even) {
  /* background-color: #e0e0e0; */
}
.addcategory {
  width: 107px;
  height: 35px;
  background: #212529;
  border: 1px solid #212529;
  color:#fff;
  border-radius: 5px;;
}
